<script>
import { Modal } from 'ant-design-vue'
//import { VideoCameraFilled } from '@ant-design/icons-vue'
import HeaderGroup from '../components/HeaderGroup.vue'
import BottomNavbar from '../components/BottomNavbar.vue'

export default {
  components: {
    HeaderGroup, BottomNavbar
  },
  data() {
    return {
      liveChannels: [],
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.fetchLiveChannel();
  },
  methods: {
    fetchLiveChannel() {
                const url = 'https://mgapi.fkdlapp.live/getData/livechannel';
                fetch( url )
                    .then(response => response.json())
                    .then(data => {
                        // 请求成功，处理数据
                        this.liveChannels = data['data'];
                        if (data['success']) {
                            console.log(this.liveChannels);
                        } else {
                            Modal.warning({
                                title: '提示',
                                content: data['msg'],
                                okText: data['data']['button_title'],
                                onOk: () => {
                                    this.$router.push({ path: data['data']['button_router'] });
                                    //this.$router.back();
                                },
                                centered: true,
                            });
                        }
                    })
                    .catch(error => {
                        // 请求失败，处理错误
                        this.error = error.message;
                    });
    },
  },
  computed: {

  }
}

</script>

<template>

<HeaderGroup title="直播" />

<main>
<div class="applist">

  <a-card title="频道列表" style="background-color: #fbfcfe;">
    <!--a-card-grid style="width: 33.3%; text-align: center">
            <div class="applogo">
                <img :src="require('@/assets/applogos/mitao.png')" />
            </div>
            <h4>绿茶直播</h4>
    </a-card-grid-->
    <a-card-grid
        v-for="item in liveChannels"
        :key="item.id"
        style="width: 33.3%; text-align: center"
        @click="this.$router.push({path: '/liveroom', query: {'id': item.id, 'channeltitle': item.title,}})">
            <div class="applogo">
                <img :src="item.logo" />
            </div>
            <h4>{{ item.title }}</h4>
            <!--p><VideoCameraFilled />{{ item.number }}</p-->
            <p class="count-tag">
                <VideoCameraOutlined /> {{ item.number }}
            </p>
    </a-card-grid>
  </a-card>
</div>



</main>
<BottomNavbar current="live" />
</template>



<style scoped>
main {
  padding-bottom: 80px;
}
.applist {
  padding: 12px 20px 12px 20px;
}
.applist h4 {
  color: #445566;
  text-align: center;
}
.applist img {
  width: 100%;
  border-radius: 12px;
}
.applogo {
  padding: 0 8px 4px 8 px;
}
.applogo>img{
  height: 64px;
}
.count-tag {
    border-radius: 50px;
	background-color: #ffeeee;
    color: #ff002e;
    padding: 2px;
    margin: 0;
}

</style>
