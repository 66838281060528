<script setup>
import { ref } from 'vue'
/*
import { useStore } from 'vuex'

const store = useStore()
const allAdvers = computed(() => store.state.advers)
const increment = () => {// 定义一个方法来调用store的action
  store.dispatch('increment')
}
*/

const sliderAds = ref([]);
const sliderAdSkeleton = {
  '0': {title: '', cover:'/assets/adslider-skeleton.png', link: '/'}
};
sliderAds.value = sliderAdSkeleton;
const fetchSliderAds = () => {
  const url = 'https://api.mdstv.xyz/getData/adslider';
  fetch( url )
    .then(response => response.json())
    .then(data => {
      // 请求成功，处理数据
      if (data['success']) {
        sliderAds.value = data['data'];
        console.log(sliderAds);
      }
    })
    .catch(error => {
      // 请求失败，处理错误
      //this.error = error.message;
      console.log(error.message);
	});
};
fetchSliderAds();

//const allAdvers = $store.state.advers;
//console.log(allAdvers);
//sliderAdvers.value = allAdvers.filter(item => item.type == 1);
//console.log(sliderAdvers);
</script>

<template>
<a-carousel :autoplay="4000" effect="scrollx" arrows>
	<template #prevArrow>
		<div class="custom-slick-arrow" style="left: 10px; z-index: 1">
			<left-circle-outlined :style="{fontSize: '20px', color: '#fff'}" />
		</div>
	</template>
	<template #nextArrow>
		<div class="custom-slick-arrow" style="right: 10px">
			<right-circle-outlined :style="{fontSize: '20px', color: '#fff'}" />
		</div>
	</template>
	<div v-for="item in sliderAds" :key="item.id">
		<a :href="item.link" target="_blank">
			<img :src="item.picname" :alt="item.title" style="height: 48vw; width: 100%;" />
		</a>
	</div>
</a-carousel>
</template>


<style scoped>

</style>
