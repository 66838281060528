<template>
  <div class="short-view" @wheel.prevent="handleMouseWheel" @touchstart="handleTouchStart" @touchmove="handleTouchMove">
    <div class="video-carousel" ref="carousel">
      <div v-for="(item, index) in shortVideos" :key="index" class="video-container" :ref="getVideoRef(index)">
        <!--video :src="item.src" :controls="false"></video-->
        <video-player
            type="application/x-mpegURL"
            :src="item.video"
            :controls="false"
            :loop="true"
            :volume="0.6"
            :autoplay="shouldAutoplay(index)"
            fluid
            playsinline
            />
        <h1 v-html="item.title"></h1>
      </div>
    </div>
    <div ref="playbtn" @click="handleVideoPlayOrPause()"
      style="position: absolute;
      top: 50%;
      left: 50%;
      padding: 10%;
      transform: translate(-50%, -50%);
      font-size: 80px;
      color: #fff;
      opacity: 0;">
      <PlayCircleOutlined />
    </div>
  </div>
</template>

<script>
  import { VideoPlayer } from '@videojs-player/vue'
  import 'video.js/dist/video-js.css'
  import { Modal } from 'ant-design-vue'; // 导入对话框组件

export default {
  data() {
    return {
      appName: '',
      currentIndex: 0,
      startY: 0,
      touching: false,
      /*
      videoList: [
        { src: 'https://f.zhxf119.net/play/12462/vlog.plist.m3u8', title: '视频标题1' },
        { src: 'https://f.zhxf119.net/play/12461/vlog.plist.m3u8', title: '视频标题2' },
        { src: 'https://f.zhxf119.net/play/12458/vlog.plist.m3u8', title: '视频标题3' }
      ]*/
      shortVideos: [],
      freeCount: 0,
      myvip: false,
      viptime: '',
      token: '',
    };
  },
  components: {
    VideoPlayer
  },
  created() {
    if ( this.$route.query.appname !== '' ) {
        this.appName = this.$route.query.appname;
    }
    this.fetchShortVideos();
    this.loadUserData();
  },
  computed: {

  },
  methods: {
    fetchShortVideos() {
      //const url = 'https://api.koudailc.net/api/blog/list?page='+page;
      const url = 'https://mgapi.fkdlapp.live/getData/shortvideos/app_name/'+this.appName;
      fetch( url )
          .then(response => response.json())
          .then(data => {
              // 请求成功，处理数据
              //this.shortVideos=data['data']['short_videos'];
              const newData = this.shortVideos.concat(data['data']['short_videos']);
              this.shortVideos = newData;
              //const firstVideo = this.$refs['vid0'][0].querySelector('video');
          })
          .catch(error => {
              // 请求失败，处理错误
              this.error = error.message;
              //message.error('视频加载失败，请刷新重试');
          });
    },
    shouldAutoplay(index) {
      /*
      if ( index==0 ) {
        return true;
      } else {
        return false;
      }*/
      return index === 0;
    },
    handleVideoPlayOrPause() {
      const videobox = this.$refs['vid'+this.currentIndex][0];
      const video = videobox.querySelector('video');
      console.log(video);
      const playbtn = this.$refs.playbtn;
      if ( video.paused ) {
        video.play();
        playbtn.style.opacity = '0';
      } else {
        video.pause();
        playbtn.style.opacity = '0.5';
      }
    },
    getVideoRef(index) {
      return 'vid'+index;
    },
    handleMouseWheel(event) {
      event.preventDefault();
      const delta = event.deltaY > 0 ? 1 : -1;
      this.changeIndex(delta);
    },
    handleTouchStart(event) {
      this.startY = event.touches[0].clientY;
      this.touching = true;
    },
    handleTouchMove(event) {
      if (!this.touching) return;
      const deltaY = event.touches[0].clientY - this.startY;
      if (Math.abs(deltaY) < 50) return; // 防止误操作
      const delta = deltaY > 0 ? -1 : 1; // 方向修正
      this.changeIndex(delta);
      this.touching = false; // 防止连续滑动
    },
    changeIndex(delta) {
      const newIndex = this.currentIndex + delta;
      this.checkVIP();
      if (newIndex >= 0 && newIndex < this.shortVideos.length) {
        this.currentIndex = newIndex;
        //尝试控制视频的暂停播放
        // 获取页面上所有的 video 元素
        const allVideos = document.querySelectorAll('video');
        // 遍历所有的 video 元素，暂停它们的播放
        allVideos.forEach(video => {
            video.pause();
        });
        const onref = 'vid'+newIndex;
        const nextVideo = this.$refs[onref][0].querySelector('video');
        nextVideo.play();
        if ( newIndex % 9 === 0 ) {
          //this.$router.push({path: '/shortview'});
          this.fetchShortVideos();
        }
      }
    },
    loadUserData() {
        const userdata = localStorage.getItem('userdata');
        if (userdata) {
            const parsedUserData = JSON.parse(userdata); // 解析JSON字符串为JavaScript对象
            this.freeCount =  parsedUserData.free_count;
            this.myvip =  parsedUserData.myvip;
            this.viptime =  parsedUserData.viptime;
            this.token =  parsedUserData.token;
            this.checkVIP();
        } else {
            // 如果未登录，则弹出对话框
            Modal.info({
                title: '提示',
                content: '请先登录后再观看哦~',
                okText: '立即登录',
                onOk: () => {
                    // 处理登录逻辑，例如跳转至登录页面
                    this.$router.push({ path: '/login' });
                },
                //cancelText: '关闭',
                centered: true,
            });
            return false;
        }
    },
    checkVIP() {
        if ( this.myvip ) {
            console.log('VIP有效');
        } else if( this.freeCount>0 ) {
            console.log('免费次数：'+this.freeCount+'-1');
            this.freeCount = this.freeCount-1;
            const userData = JSON.parse(localStorage.getItem('userdata'));
            userData.free_count = this.freeCount;
            // 将修改后的对象重新存储到 localStorage 中
            localStorage.setItem('userdata', JSON.stringify(userData));
            if ( this.freeCount == 0 ) {
                fetch('https://api.mdstv.xyz/getData/freecount_clear/token/'+this.token);
            }
        } else {
            // 如果非VIP会员，则弹出对话框
            Modal.info({
                title: '提示',
                content: '免费次数已用完，请开通VIP再继续~',
                okText: '立即开通',
                onOk: () => {
                    // 处理登录逻辑，例如跳转至登录页面
                    this.$router.push({ path: '/userhome' });
                },
                //cancelText: '关闭',
                centered: true,
            });
            return false;
        }
      },
  },
  watch: {
    currentIndex(newIndex) {
      const container = this.$refs.carousel;
      const offset = newIndex * container.clientHeight;
      container.scrollTo({ top: offset, behavior: 'smooth' });
    }
  }
};
</script>

<style scoped>
/*
.short-view {
  height: calc(100vh);
  height: 50%;
  background: #000;
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
}
*/
.short-view {
  height: calc(100% - 68px);
  width: 100%;
  background: #000;
  position: absolute;
  left:0 ;
  top: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.video-carousel,
.video-carousel .video-container,
.video-js.v-video-player {
  height: 100%;
  width: 100%;
}

.video-carousel {
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
}

.video-carousel .video-container {
  /*height: calc(100vh);/
  height: 100%;
  width: 100%;*/
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.video-js.v-video-player {
  padding: 0;
  background-color: #111;
}

.video-container h1 {
  position: absolute;
  bottom: 12px;
  left: 12px;
  color: #ddd;
  font-size: 20px; /* 字号22px */
}
</style>
