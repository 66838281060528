<template>
<TopBar title="社区" />
    <div class="app-main">
        <div style="padding: 12px;">
            <a-divider>最新帖子</a-divider>
              <div v-for="item in posts" :key="item.id">
              <a-comment>
                <template #actions>
                    <span style="cursor: auto">
                        <StarOutlined /> {{ item.favorited }}
                    </span>
                    <span><LikeOutlined /> {{ item.liked }}</span>
                    <span><CommentOutlined /> {{ item.comment_count }}</span>
                </template>
                <template #author>{{ item.source_uname }}</template>

                <template #avatar>
                  <a-avatar><template #icon><UserOutlined /></template></a-avatar>
                </template>
                <template #content>
                  <p >
                    {{ item.title }}
                  </p>
                  <div>
                      <a-row>
                        <template v-if="item.video && item.cover">
                            <a-col :span="8">
                                <!--a-image :data-src="fetchImageData(item.cover)" :src="item.cover" style="width: 100%;"/-->
                                <div style="position: relative;" @click="handleVideoClick(item.id)">
                                    <div class="playbtn">
                                        <PlayCircleOutlined />
                                    </div>
                                    <img :src="item.cover" style="width: 100%;"/>
                                </div>
                            </a-col>
                        </template>
                        <a-col :span="8" v-for="(imgurl, index) in item.imgs" :key="index">
                            <!--a-image :data-src="fetchImageData(imgurl)" :src="imgurl" style="width: 100%;"/-->
                            <a-image :src="imgurl" style="width: 100%;"/>
                        </a-col>
                      </a-row>
                  </div>
                </template>
                <template #datetime>
                  <a-tooltip>
                    <span>{{ item.create_time }}</span>
                  </a-tooltip>
                </template>
              </a-comment>
              <a-divider style="margin: 0;" />
            </div>
        </div>
        <a-pagination page-size="20" :current="pageCurrent" :total="pageTotal" @change="handlePageChange" />
    </div>
    <BottomNavbar current="post" />
</template>

<script>
import { Modal } from 'ant-design-vue'
import TopBar from '../components/TopBar.vue'
import BottomNavbar from '../components/BottomNavbar.vue'
import CryptoJS from 'crypto-js'

export default {
    components: {
        TopBar, BottomNavbar
    },
    data() {
        return {
            theme: 'dark',
            //cid: null,
            menuCurrent: 20,
            pageCurrent: 1,
            pageTotal: null,
            cateTitle: '分类名称',
            posts: [],
            notice: '',
        };
    },
    created() {
        window.scrollTo(0, 0);
        //this.cid = this.$route.query.cid;
        if ( this.$route.query.page > 1 ) {
            this.pageCurrent = this.$route.query.page;
        }
        this.fetchPostList();
    },
    computed: {
        categories() {
            return this.$store.state.categories;
        },
        sliderAD() {
            const allAdvers = this.$store.state.advers;
            return allAdvers.filter(item => item.type == 1);
        },
        listAd1() {
            const allAdvers = this.$store.state.advers;
            return allAdvers.filter(item => item.type == 4);
        },
        listAd2() {
            const allAdvers = this.$store.state.advers;
            return allAdvers.filter(item => item.type == 5);
        },
    },
    mounted() {
        
    },
    methods: {
        fetchPostList() {
            //const cid = this.cid
            const page = this.pageCurrent;
            //const url = 'https://api.koudailc.net/api/blog/list?page='+page;
            const url = 'https://mgapi.fkdlapp.live/getData/postlist/page/'+page;
            fetch( url )
                .then(response => response.json())
                .then(data => {
                    // 请求成功，处理数据
                    this.posts = data['data']['posts'];
                    this.pageTotal = data['data']['page_total'];
                    this.parseImages();
                })
                .catch(error => {
                    // 请求失败，处理错误
                    this.error = error.message;
                });
        },
        /*
        sendHttpRequest(url) {
            return fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch data. Status: ' + response.status);
                    }
                    return response.arrayBuffer();
                });
        },*/
        parseImages() {
            const postsData = this.posts;
            postsData.forEach(post=> {
                if (post.cover) {
                    return fetch( post.cover )
                    .then(response => response.arrayBuffer())
                    .then(data => {
                        const imgBase64 = this.decryptImage(data, post.cover);
                        post.cover = imgBase64;
                    });
                }
                if (post.imgs && post.imgs.length>0) {
                    post.imgs.forEach((imgurl, index)=> {
                        //post.imgs[index] = 'https://cdn.sofan.one:65533/static/img/douyin.png';
                        return fetch( imgurl )
                        .then(response => response.arrayBuffer())
                        .then(data => {
                            const imgBase64 = this.decryptImage(data, imgurl);
                            post.imgs[index] = imgBase64;
                        });
                    });
                }
            });
        },
        replaceImageSRC(url) {
            return fetch( url )
                .then(response => response.arrayBuffer())
                .then(data => {
                    const imgdata = this.decryptImage(data, url);
                    const imgElement = document.querySelector('img[src="' + url + '"]');
                    if (imgElement) {
                        imgElement.src = imgdata;
                    }
                });
        },
        fetchImageData(url) {
            return fetch( url )
                .then(response => response.arrayBuffer())
                .then(data => {
                    const imgBase64 = this.decryptImage(data, url);
                    return imgBase64;
                });
        },
        decryptImage(imgdata, url) {
            const completeFilename = new URL(url).pathname.split('/').pop();
            console.log("url:"+url);
            const filename = completeFilename.split('.')[0];
            //const extname = completeFilename.split('.')[1];
            const key = CryptoJS.MD5(filename);
            //var b64data = btoa(String.fromCharCode.apply(null, new Uint8Array(data)));
            const unit8data = new Uint8Array(imgdata);
            const encryptedData = CryptoJS.lib.WordArray.create(unit8data);
            const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedData);
            const decryptedData = CryptoJS.AES.decrypt(encryptedBase64, key, { iv: key, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
            const decryptedBase64 = decryptedData.toString(CryptoJS.enc.Base64);
            const imageSrc = "data:image/png;base64," + decryptedBase64;
            return imageSrc;
        },
        handlePageChange(page) {
            this.pageCurrent = page;
            this.$router.push({ path: '/postlist', query: { 'page': page } });
            this.fetchPostList();
        },
        handleVideoClick(id) {
            const userdata = localStorage.getItem('userdata');
            if (userdata) {
                const parsedUserData = JSON.parse(userdata); // 解析JSON字符串为JavaScript对象
                // eslint-disable-next-line no-unused-vars
                const viptime = parsedUserData.viptime;
                const myvip = parsedUserData.myvip;
                // eslint-disable-next-line no-unused-vars
                const token = parsedUserData.token;
                if ( myvip == true ) {
                    // 如果VIP有效，则执行路由跳转
                    this.$router.push({ path: '/postvideo', query: { id: id } });
                } else {
                    // 如果非VIP会员，则弹出对话框
                    Modal.confirm({
                        title: '提示',
                        content: 'VIP资源仅VIP会员可观看哦~',
                        okText: '立即开通',
                        onOk: () => {
                            // 处理登录逻辑，例如跳转至登录页面
                            this.$router.push({ path: '/userhome' });
                        },
                        cancelText: '关闭',
                        centered: true,
                    });
                }
            } else {
                // 如果未登录，则弹出对话框
                Modal.confirm({
                    title: '提示',
                    content: '需要登录后才可观看哦~',
                    okText: '立即登录',
                    onOk: () => {
                        // 处理登录逻辑，例如跳转至登录页面
                        this.$router.push({ path: '/login' });
                    },
                    cancelText: '关闭',
                    centered: true,
                });
            }
        },
    },
}
</script>


<style scoped>
.app-main {
    padding: 0 0 108px 0;
}
#top-menu.menu-container ul {
    margin-right: -25px!important;
}
#top-menu.menu-container ul>li {
    margin-right: -8px!important;
}
.ant-comment .ant-comment-inner {
    padding-bottom: 8px!important;
}
.playbtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    color: #fff;
}

</style>