<template>
    <TopBar title="APP下载" />
    <div class="app-main">
        <!--div class="buttons-list">
            <a-button @click="gotoDownload('ios')">苹果APP下载</a-button>
            <a-button @click="gotoDownload('android')">安卓APP下载</a-button>
            <a href="" @click="gotoDownload('backup')">备用下载页</a>
        </div-->
        <div class="buttons-list">
            <a-button
                :href="iosLink"
                size="large"
                target=""
                type="default"
                block
                >
                <template #icon><AppleOutlined /></template>
                苹果APP下载
            </a-button>
            <a-button
                :href="androidLink"
                size="large"
                target=""
                type="default"
                block
                >
                <template #icon><AndroidOutlined /></template>
                安卓APP下载
            </a-button>
            <a-button
                :href="backupLink"
                size="large"
                target="_blank"
                type="link"
                block
                >
                <template #icon><ChromeOutlined /></template>
                备用下载页
            </a-button>
        </div>
    </div>
    <BottomNavbar current="userhome" />
</template>

<script>
//import { Modal } from 'ant-design-vue'; // 导入对话框组件
import TopBar from '../components/TopBar.vue'
import BottomNavbar from '../components/BottomNavbar.vue'

export default {
    components: {
        TopBar, BottomNavbar,
    },
    data() {
        return {
            iosLink: '',
            androidLink: '',
            backupLink: '',
        };
    },
    created() {
        window.scrollTo(0, 0);
        this.fetchDownloadLinks();
    },
    mounted() {
        
    },
    methods: {
        fetchDownloadLinks() {
            const url = 'https://mgapi.fkdlapp.live/getData/download_links';
            fetch( url )
                .then(response => response.json())
                .then(data => {
                    this.iosLink = data['data']['ios_link'];
                    this.androidLink = data['data']['android_link'];
                    this.backupLink = data['data']['backup_link'];
                })
                .catch(error => {
                    // 请求失败，处理错误
                    this.error = error.message;
                });
        },
        gotoDownload(linktype) {
            if ( linktype == 'ios' ) {
                window.loaction.href = this.iosLink;
            }
            if ( linktype == 'android' ) {
                window.loaction.href = this.androidLink;
            }
            if ( linktype == 'backup' ) {
                window.loaction.href = this.backupLink;
            }
        }
    },
    computed: {

    }
}
</script>


<style scoped>
.app-main {
    padding: 0 0 108px 0;
}
#top-menu.menu-container ul {
    margin-right: -25px!important;
}
#top-menu.menu-container ul>li {
    margin-right: -8px!important;
}

.buttons-list {
    padding: 20px;
}
.buttons-list a {
    margin-bottom: 12px;
}
</style>