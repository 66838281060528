import { createStore } from 'vuex';

export default createStore({
  state: {
    categories: [],
    advers: [],
    count: 1,
  },
  mutations: {
    saveCategories(state, data) {
      state.categories = data;
    },
    saveAdvers(state, data) {
      state.advers = data;
    },
    addCount(state, num) {
        state.count += num || 1;
    },
  },
  actions: {
    async fetchCategories({ commit }) {
      try {
        const response = await fetch('https://api.mdstv.xyz/getData/catelist');
        const data = await response.json();
        commit('saveCategories', data['data']);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    },
    async fetchAdvers({ commit }) {
      try {
        const response = await fetch('https://api.mdstv.xyz/getData/adlist');
        const data = await response.json();
        commit('saveAdvers', data['data']);
      } catch (error) {
        console.error('Failed to fetch advers:', error);
      }
    },
  },
});