<template>
    <TopBar title="登录 / 注册" />
    <main>
        <!--header><h3>登录 / 注册</h3></header>
        <a-page-header
            style="background-color: #000;"
            title="我的"
            sub-title="登录 / 注册"
            @back="this.$router.back()"
            /-->
        <div style="width: 80%; margin: 0 auto; padding: 20% 0 20% 0;">
            <a-tabs v-model:select-key="activeTab" v-model:activeKey="activeKey"  size="large" centered>
                <a-tab-pane key="login" tab="登 录">
                    <a-form
                        :model="loginState"
                        name="login_form"
                        class="login-form"
                        @submit.prevent="loginSubmit"
                        >
                        <a-form-item
                          label="账号（手机号）"
                          name="username">
                          <a-input v-model:value="loginState.username" size="large">
                            <template #prefix>
                              <MobileOutlined class="site-form-item-icon" />
                            </template>
                          </a-input>
                        </a-form-item>
                        <a-form-item
                          label="密码"
                          name="password">
                            <a-input-password v-model:value="loginState.password" size="large">
                                <template #prefix>
                                <LockOutlined class="site-form-item-icon" />
                                </template>
                            </a-input-password>
                        </a-form-item>
                
                    <!--a-form-item>
                      <a-form-item name="remember" no-style>
                        <a-checkbox v-model:checked="formState.remember">Remember me</a-checkbox>
                      </a-form-item>
                      <a class="login-form-forgot" href="">Forgot password</a>
                    </a-form-item-->
                
                        <a-form-item>
                            <a-button type="primary" html-type="submit" class="login-form-button" size="large" block>
                                立即登录
                            </a-button>
                        </a-form-item>
                    </a-form>
                </a-tab-pane>
                <a-tab-pane key="reg" tab="注 册" force-render>
                    <a-form
                        :model="regState"
                        name="reg_form"
                        class="login-form"
                        @submit.prevent="regSubmit"
                        >
                        <a-form-item
                          label="账号（手机号）"
                          name="username">
                          <a-input v-model:value="regState.username" size="large">
                            <template #prefix>
                              <UserOutlined class="site-form-item-icon" />
                            </template>
                          </a-input>
                        </a-form-item>
                        <a-form-item
                          label="密码"
                          name="password">
                            <a-input-password v-model:value="regState.password" size="large">
                                <template #prefix>
                                <LockOutlined class="site-form-item-icon" />
                                </template>
                            </a-input-password>
                        </a-form-item>
                        <a-form-item
                          label="确认密码"
                          name="repwd">
                            <a-input-password v-model:value="regState.repwd" size="large">
                                <template #prefix>
                                <LockOutlined class="site-form-item-icon" />
                                </template>
                            </a-input-password>
                        </a-form-item>
                        <a-form-item
                          label="邀请码（必填）"
                          name="invitation_code">
                          <a-input v-model:value="regState.invcode" size="large">
                            <template #prefix>
                              <NumberOutlined class="site-form-item-icon" />
                            </template>
                          </a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-button type="primary" html-type="submit" class="login-form-button"  size="large" block>
                                确认注册
                            </a-button>
                        </a-form-item>
                    </a-form>
                </a-tab-pane>
            </a-tabs>
        </div>
    </main>
</template>

<script>
import { message } from 'ant-design-vue';
import TopBar from '../components/TopBar.vue';

export default {
  components: {
    TopBar,
  },
  data() {
    return {
      activeTab: 'reg',
      activeKey: 'login',
      loginState: {
        username: '',
        password: '',
      },
      regState: {
        username: '',
        password: '',
        repwd: '',
        invcode: '', //invitation code
      },
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    async loginSubmit() {
        // 登录成功后将用户名和令牌保存到localStorage中
        /*
        const username = '123123';
        const vip = 1;
        const endtime = 1798867821;
        const token = '123123';
        localStorage.setItem('userinfo', JSON.stringify({ username, vip, endtime, token }));*/
      try {
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // 修改Content-Type为表单数据格式
            },
            body: new URLSearchParams(this.loginState).toString(), // 使用URLSearchParams构建表单数据
          };
        // 向后端发送登录请求
        const response = await fetch('https://mgapi.fkdlapp.live/getData/login', requestOptions);
        const data = await response.json();

        // 处理登录成功的情况
        if (data.success) {
          // 登录成功，执行跳转等操作
          message.success('登录成功，欢迎回来！', 3);
          localStorage.setItem('userdata', JSON.stringify(data.data));
          this.$router.push('/userhome');
        } else {
          // 登录失败，显示错误信息
            console.log(data.msg);
            message.error(data.msg, 3);
        }
      } catch (error) {
        // 处理请求失败的情况
        message.error('未知错误！', 2);
        console.log(this.loginState);
        console.log('Login error:', error);
      }
    },

    async regSubmit() {
      try {
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // 修改Content-Type为表单数据格式
            },
            body: new URLSearchParams(this.regState).toString(), // 使用URLSearchParams构建表单数据
          };
        // 向后端发送注册请求
        const response = await fetch('https://mgapi.fkdlapp.live/getData/reg', requestOptions);
        const data = await response.json();

        // 处理注册成功的情况
        if (data.success) {
          // 注册成功，执行跳转等操作
          message.success('注册成功！', 3);
          this.loginState.username = data.data.username;
          this.activeTab="login";
          this.activeKey="login";
        } else {
          // 注册失败，显示错误信息
            console.log(data.msg);
            message.error(data.msg, 3);
        }
      } catch (error) {
        // 处理请求失败的情况
        message.error('未知错误！', 2);
        console.log(this.loginState);
        console.log('Login error:', error);
      }
    },

  }
};
</script>


<style scoped>
/*.app-main {
    padding: 0 0 108px 0;
}*/
</style>