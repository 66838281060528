<template>
    <div class="app-main">
        <a-page-header
            style="border: 1px solid rgb(235, 237, 240)"
            title="会员中心"
            sub-title="修改密码"
            @back="this.$router.back()"
            />
        <div style="width: 80%; margin: 12px auto;">
                    <a-form
                        :model="passwordState"
                        name="password_form"
                        class="password-form"
                        @submit.prevent="passwordSubmit"
                        >
                        <a-form-item
                          label="原密码"
                          name="password">
                          <a-input-password v-model:value="passwordState.password" size="large">
                            <template #prefix>
                              <LockOutlined class="site-form-item-icon" />
                            </template>
                          </a-input-password>
                        </a-form-item>
                        <a-form-item
                          label="新密码"
                          name="newpwd">
                            <a-input-password v-model:value="passwordState.newpwd" size="large">
                                <template #prefix>
                                <LockOutlined class="site-form-item-icon" />
                                </template>
                            </a-input-password>
                        </a-form-item>
                        <a-form-item
                          label="确认新密码"
                          name="renewpwd">
                            <a-input-password v-model:value="passwordState.renewpwd" size="large">
                                <template #prefix>
                                <LockOutlined class="site-form-item-icon" />
                                </template>
                            </a-input-password>
                        </a-form-item>
                        <a-form-item>
                            <a-button  type="primary" html-type="submit" class="login-form-button" size="large" block>
                                确认修改
                            </a-button>
                        </a-form-item>
                    </a-form>
        </div>
    </div>
</template>

<script>
import { message } from 'ant-design-vue';

export default {
  data() {
    return {
      passwordState: {
        username: '',
        password: '',
        newpwd: '',
        renewpwd: '',
      },
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    async passwordSubmit() {
      const userData = JSON.parse(localStorage.getItem('userdata'));
      this.passwordState.username = userData.username;
      try {
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // 修改Content-Type为表单数据格式
            },
            body: new URLSearchParams(this.passwordState).toString(), // 使用URLSearchParams构建表单数据
          };
        // 向后端发送修改密码请求
        const response = await fetch('https://mgapi.fkdlapp.live/getData/pwd_update', requestOptions);
        const data = await response.json();

        // 处理修改成功的情况
        if (data.success) {
          message.success('修改成功', 3);
        } else {
          // 修改失败，显示错误信息
            console.log(data.msg);
            message.error(data.msg, 3);
        }
      } catch (error) {
        // 处理请求失败的情况
        message.error('未知错误！', 2);
        console.log(this.passwordState);
        console.log('Login error:', error);
      }
    },

  }
};
</script>


<style scoped>
.app-main {
    padding: 0 0 108px 0;
}
</style>