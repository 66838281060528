<script setup>
import { ref, defineProps, watch } from 'vue'


const props = defineProps({
    title: String
});
const PageTitle = ref('');

/*
if ( props.title ) {
    PageTitle.value = props.title;
}
*/
watch(
  () => props.title,
  (newTitle) => {
    if (newTitle) {
      PageTitle.value = newTitle;
    }
  },
  { immediate: true }
);
</script>

<template>
<header>
<h3>{{ PageTitle }}</h3>
</header>
</template>


<style scoped>
header {
  height: 52px;
  background-color: #001122;
  color: #f0f2f6;
}
header h3 {
  text-align: center;
  line-height: 52px;
}
</style>
