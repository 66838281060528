<script>
import { Modal } from 'ant-design-vue'
//import { VideoCameraFilled } from '@ant-design/icons-vue'
//import HeaderGroup from '../components/HeaderGroup.vue'
import TopBar from '../components/TopBar.vue'
import BottomNavbar from '../components/BottomNavbar.vue'

export default {
  components: {
    TopBar, BottomNavbar
  },
  data() {
    return {
      channelId: '',
      channelTitle: '直播',
      liveChannels: [],
      liveRooms: [],
    }
  },
  created() {
    window.scrollTo(0, 0);
    if ( this.$route.query.id >0 ) {
      this.channelId = this.$route.query.id;
      this.fetchLiveRoom();
    }
    if ( this.$route.query.channeltitle !== '' ) {
      this.channelTitle = this.$route.query.channeltitle;
    }
  },
  methods: {
    fetchLiveRoom() {
                const url = 'https://mgapi.fkdlapp.live/getData/liveroom/cid/'+this.channelId;
                fetch( url )
                    .then(response => response.json())
                    .then(data => {
                        // 请求成功，处理数据
                        this.liveChannels = data['data'];
                        if (data['success']) {
                            this.liveRooms = data['data'];
                            console.log(this.liveRooms);
                        } else {
                            Modal.warning({
                                title: '提示',
                                content: data['msg'],
                                okText: data['data']['button_title'],
                                onOk: () => {
                                    this.$router.push({ path: data['data']['button_router'] });
                                    //this.$router.back();
                                },
                                centered: true,
                            });
                        }
                    })
                    .catch(error => {
                        // 请求失败，处理错误
                        this.error = error.message;
                    });
    },
    handleCardClick(id, url) {
        const userdata = localStorage.getItem('userdata');
        if (userdata) {
            const parsedUserData = JSON.parse(userdata); // 解析JSON字符串为JavaScript对象
            // eslint-disable-next-line no-unused-vars
            const viptime = parsedUserData.viptime;
            const myvip = parsedUserData.myvip;
            // eslint-disable-next-line no-unused-vars
            const token = parsedUserData.token;
            if ( myvip == true ) {
                // 如果VIP有效，则执行路由跳转
                //this.$router.push({ path: '/appvideo', query: { id: vid } });
                //window.location.href = url;
                window.open(url);
            } else {
                // 如果非VIP会员，则弹出对话框
                Modal.confirm({
                    title: '提示',
                    content: 'VIP资源仅VIP会员可观看哦~',
                    okText: '立即开通',
                    onOk: () => {
                        // 处理登录逻辑，例如跳转至登录页面
                        this.$router.push({ path: '/userhome' });
                    },
                    cancelText: '关闭',
                    centered: true,
                });
            }
        } else {
            // 如果未登录，则弹出对话框
            Modal.confirm({
                title: '提示',
                content: '需要登录后才可观看哦~',
                okText: '立即登录',
                onOk: () => {
                    // 处理登录逻辑，例如跳转至登录页面
                    this.$router.push({ path: '/login' });
                },
                cancelText: '关闭',
                centered: true,
            });
        }
    },
  },
  computed: {

  }
}

</script>


<template>
<TopBar :title="channelTitle" />
<header class="topbar">
    <div class="goback-button" @click="$router.go(-1)"><LeftOutlined /></div>
    <h3>{{ channelTitle }}</h3>
</header>
<main>
    <!--h3 style="font-size: 18px; color: #223344; margin: 20px 0 12px 20px;">直播房间</h3-->
    <div class="roomlist">
        <a-row :gutter="[12,18]">
            <a-col :span="12" v-for="item in liveRooms" :key="item.id">
                <a :href="item.address" class="room-card" @click="handleCardClick(item.id, item.address)">
                    <div class="cover">
                        <img :src="item.cover" />
                        <div class="room-title">{{ item.title }}</div>
                    </div>
                </a>
            </a-col>
        </a-row>
    </div>
</main>
<BottomNavbar current="live" />
</template>


<style scoped>
main {
  padding-bottom: 80px;
}

header.topbar {
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
    height: 52px;
}
.goback-button {
    height: 52px;
    width: 52px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 16px;
    opacity: 0.75;
    z-index: 101;
    color: #fff;
    font-size: 20px;
}

.roomlist {
  padding: 68px 20px 12px 20px;
}

a.room-card {
    height: 112px;
    width: 100%;
    background-color: #e5eaee;
    border-radius: 12px;
    display: block;
}
.room-card .cover, .cover img {
    height: 100%;
    width: 100%;
    border-radius: 12px;
}

.cover img {

}

.room-title {
    position: absolute;
    bottom: 8px;
    left: 20px;
    color: #fff;
}

.count-tag {
    border-radius: 50px;
	background-color: #ffeeee;
    color: #ff002e;
    padding: 2px;
    margin: 0;
}


</style>
