<template>
    <a-config-provider
        :theme="{
            token: {
                colorPrimary: '#ff002e',
            },
        }"
        :config-provider-wavesclick="false"
        >
    <div id="app">
        <router-view />
		<!--RouterView /-->
        <div class="startup-overlay" v-if="showOverlay">
            <div class="ad-container">
                <a href="https://sbl-sa2323.com/?a=663161691" target="_blank">
                    <img :src="require('@/assets/3pC437X604dZ.gif')" alt="Advertisement" class="ad-image" />
                </a>
                <!--button shape="round" size="large" @click="closeOverlay" class="close-button">×</button-->
                <div class="close-button" @click="closeOverlay">
                    <!--DownloadOutlined out-lined-color="#fff" /-->
                    <CloseCircleOutlined />
                </div>
            </div>
          </div>
    </div>
    </a-config-provider>
</template>

<script>
//import { theme } from 'ant-design-vue';
import { Modal } from 'ant-design-vue'

export default {
  name: 'App',
  components: {
    
  },
  data() {
    return {
      currentVersion: '1.2.0',
      latestVersion: '1.2.0',
      showOverlay: false,//true, // 控制遮罩广告是否显示
      carouselItems: [],
    };
  },
  created() {
    this.versionUpdateCheck(); // 版本更新检查
  },
  mounted() {
    this.$store.dispatch('fetchCategories');
    this.$store.dispatch('fetchAdvers');
  },
  methods: {
    versionUpdateCheck() {
      const url = 'https://mgapi.fkdlapp.live/getData/latest_version';
      fetch( url )
        .then(response => response.json())
        .then(data => {
          if (data['success']) {
            this.latestVersion = data['data']['latest_version'];
            console.log('当前APP版本：'+this.currentVersion);
            console.log('最新APP版本：'+this.latestVersion);
            if ( this.latestVersion > this.currentVersion ) {
              Modal.confirm({
                title: '版本更新',
                content: '最新版本已发布，请及时进行更新！网页版用户在浏览器刷新即可',
                okText: '立即更新',
                onOk: () => {
                  this.$router.push({ path: '/appdownload' });
                },
                cancelText: '以后再说',
                  centered: true,
              });
            }
          }
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    closeOverlay() {
      this.showOverlay = false; // 关闭遮罩广告
    },
  },
}
</script>

<style>
body {
    background-color: #f0f2f6;
}

#app {
  background-color: #f0f2f6;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
}

.ant-btn.ant-btn-primary {
    /*background: linear-gradient(240deg,#c53afc 20%,#EB1492 50%,#ff003d 80%);*/
    background-color: #ff002e;
    color: #fff;
    box-shadow: none;
    border-color: rgba(255,255,255,0.5);
}

.ant-btn.ant-btn-default:hover {
    border-color: #fff;
    color: #fff;
}

a-divier {
    color: #ccddee;
}


.app-topbar input.ant-input {
    background: none;
    border: 0;
    box-shadow: none;
}
.app-topbar button.ant-input-search-button {
    height: 30px;
    width: 30px;
    background-color: #ff7aa7;
    border-radius: 50px!important;
    padding: 0;
    margin-right: 4px;
}

.ant-card .ant-card-body {
    padding: 8px;
}
.ant-card-meta>.ant-card-meta-detail>.ant-card-meta-title {
    /*margin-bottom: 0!important;
    overflow: hidden;*/
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 设置行数 */
    white-space: unset;
    font-size: 15px;
    font-weight: normal;
}

.startup-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75); /* 半透明黑色背景 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.startup-overlay>.ad-container {
  position: relative;
  width: 75%;
  height: 414px;
}

.startup-overlay .ad-image {
    height: 360px;
    width: 100%;
    border-radius: 12px;
}

.startup-overlay .close-button {
  position: absolute;
  bottom: 2px;
  left: 50%;
  cursor: pointer;
  margin-left: -18px;
  font-size: 36px;
  width: 36px;
  height: 36px;
  color: #fff;
  /*background: transparent;*/
}
</style>
