import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/reset.css';
import * as AntIcons from '@ant-design/icons-vue';
import store from './store';
import router from './router';
//import VueVideoPlayer from '@videojs-player/vue'
//import 'video.js/dist/video-js.css'

const app = createApp(App);
// 设置 Vue.config.productionTip 为 false，禁用生产环境的提示信息
app.config.productionTip = false;

// 注册所有 Ant Design Vue 图标组件
Object.keys(AntIcons).forEach((key) => {
  if (key !== 'default') {
    app.component(key, AntIcons[key]);
  }
});

app.use(Antd).use(store).use(router).mount('#app');